<script setup lang="ts">
import { twMerge } from 'tailwind-merge';

// PROPS
const props = defineProps({
    title: { type: String, default: undefined },
    subTitle: { type: String, default: undefined },
    class: { type: String, default: undefined },
});

// DATA
const mergedClass = twMerge('mb-9', props.class);
</script>

<template>
    <div :class="mergedClass">
        <h1 class="text-4xl font-bold leading-10" v-bind="$attrs" :class="subTitle && 'mb-4'">
            <slot>{{ title }}</slot>
        </h1>
        <p v-if="subTitle" class="text-base font-normal leading-normal">{{ subTitle }}</p>
    </div>
</template>
